import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import GithubSvg from "../../public/github.svg";
import GoogleSvg from "../../public/google.svg";
import EmailSvg from "../../public/mail.svg";
import MicrosoftSvg from "../../public/microsoft.svg";
import SAMLSvg from "../../public/saml.svg";

const CoreButton = styled(Button)({
  textTransform: "none",
  width: "100%",
  fontSize: "14px",
  borderRadius: "4px",
});

const OutlineButton = styled(CoreButton)({
  color: "#5E5E5E",
  borderColor: "#5E5E5E",
  borderRadius: "4px",
  padding: "7px 20px"
});

export default function LoginButton({ provider, isSignUp, ...props }: any) {
  let prefix = isSignUp ? "Sign up with " : "Sign in with ";
  let BootstrapButton = OutlineButton;
  let variant = "outlined";
  let Svg, text;
  switch (provider) {
    case "google":
      Svg = GoogleSvg;
      text = prefix + "Google";
      break;
    case "microsoft":
      Svg = MicrosoftSvg;
      text = prefix + "Microsoft";
      break;
    case "saml":
      Svg = SAMLSvg;
      text = prefix + "SAML";
      break;
    case "github":
      Svg = GithubSvg;
      text = prefix + "Github";
      break;
    case "email":
      BootstrapButton = CoreButton;
      Svg = EmailSvg;
      text = prefix + "email";
      variant = "contained";
      break;
    default:
      throw "Unsupported: " + provider;
  }

  return (
    <BootstrapButton
      {...props}
      variant={variant}
      size="large"
      startIcon={
        <SvgIcon>
          <Svg />
        </SvgIcon>
      }
      disableElevation
    >
      {text}
    </BootstrapButton>
  );
}
