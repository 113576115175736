import colorlog from "@libs/colorlog";

const log = colorlog("libs");

// Lists all allowed redirects from IAM
export const PAGES: {
  [key: string]: string;
} = {
  cloud: "cloud", // Special type of page, redirects to the cloud URL
  changepassword: "changepassword",
  forgotpassword: "forgotpassword",
  login: "login",
  logout: "logout",
  signup: "signup",
  profile: "profile",
  twofactorauthlogin: "twofactorauthlogin",
  acceptinvitation: "acceptinvitation",
  resetpassword: "resetpassword",
  emailverification: "emailverification",
  twofactorauthconfig: "twofactorauthconfig",
};

export const getPath = (name: string, redirect?: string): string => {
  let path = `/${PAGES[name]}`;
  if (!path) {
    log.error("getPath::unknown page:", name);
    return `/${PAGES.profile}`;
  }
  if (redirect) {
    path += `?redirect=${redirect}`;
  }
  return path;
};
