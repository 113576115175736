import * as Sentry from "@sentry/nextjs";

import colorlog from "@libs/colorlog";
import { APPS } from "@libs/constants";
import { getPath, PAGES } from "@libs/web/urls";

const log = colorlog("libs");

const setCloudFlow = async ({
  token,
  auth,
  router,
  tracking,
  setOpen,
  ctx,
}: {
  token?: string;
  auth: any;
  router: any;
  tracking?: any;
  setOpen: Function;
  ctx: any;
}): Promise<any> => {
  const headers: HeadersInit = new Headers();
  if (token) {
    headers.set("authorization", "GIDP " + token);
  }
  headers.set("content-type", "application/json");
  const { query } = router;
  const { redirectapp, redirect_uri, state } = query;
  let resp = {} as Response;
  try {
    resp = await fetch("/api/code", {
      method: "POST",
      headers,
      body: JSON.stringify({ tracking, redirectapp, redirect_uri }),
    });
  } catch (e) {
    // network error
    resp = {
      ...resp,
      redirected: false,
      ok: false,
      json: () =>
        Promise.resolve({
          error: "Failed to fetch",
          message: "Error fetching login data",
        }),
    };
    Sentry.captureException(e);
  }
  if (resp.status >= 500) {
    Sentry.captureException(
      new Error(`Server error on /code call: ${resp.status}`)
    );
  }

  if (resp.redirected) {
    // Accomodates for SP initiated SAML flow
    // Handle cloud redirect app and if is on the query param redirectpath also.
    await auth.signOut();
    const { query } = router;
    const { redirectapp, redirectpath } = query;
    const qParam =
      redirectapp && redirectpath
        ? `&redirectapp=${redirectapp}&redirectpath=${redirectpath}`
        : redirectapp
        ? `&redirectapp=${redirectapp}`
        : "";
    await router.push(`${resp.url}${qParam}`, undefined, {});
    return;
  } else if (resp.ok) {
    const { code, userId } = await resp.json();
    log.debug(`setCloudFlow::code: ${code} | userId: ${userId}`);

    if (redirectapp && redirectapp !== APPS.cloud) {
      if (!redirect_uri) {
        log.error(
          "setCloudFlow:: redirect_uri is undefined for redirectapp",
          redirectapp
        );
        return {
          error: "redirect_uri is undefined",
          message: "redirect_uri is undefined",
        };
      }
      const hasQueryParams = redirect_uri.toString().includes("?");
      router.push(
        `${redirect_uri.toString()}${
          hasQueryParams ? "&" : "?"
        }userId=${userId}&code=${code}&state=${state}`
      );
    } else {
      await router.replace({
        pathname: getPath(PAGES.cloud) + "/auth",
        query: {
          code: code,
          userId: userId,
          ...router.query,
        },
      });
    }
  } else {
    const {
      message,
      error,
      mfaCheck,
      mfaRequiresSetup,
      emailVerified = true,
    } = await resp.json();
    if (emailVerified === false && token) {
      ctx.setLoading(false);
      setOpen(true);
    } else if (mfaCheck || mfaRequiresSetup) {
      delete router.query["user"];
      delete router.query["provider"];
      if (mfaCheck) {
        await router.replace({
          pathname: getPath(PAGES.twofactorauthlogin),
          query: {
            ...router.query,
          },
        });
      } else {
        // mfaRequiresSetup
        await router.replace({
          pathname: getPath(PAGES.twofactorauthconfig),
          query: {
            ...router.query,
          },
        });
      }
    } else {
      return {
        error,
        message,
      };
    }
  }
};

export default setCloudFlow;
