import { AppContext } from "@components/contexts/AppContext";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import type { NextPage } from "next";
import Image from "next/image";
import { useContext } from "react";
import CenteredBox from "./CenteredBox";

interface SplitScreenProps {
  LeftPanel: JSX.Element;
  RightPanel: JSX.Element;
}

const SplitScreen: NextPage<SplitScreenProps> = ({ LeftPanel, RightPanel }) => {
  const ctx = useContext(AppContext);

  return (
    <CenteredBox>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            md={6}
            style={{
              backgroundColor: "var(--colours-primary-dark-grey, #333)",
            }}
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
              }}
            >
              {LeftPanel}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Fade in={true} timeout={500}>
              <Paper
                variant="outlined"
                sx={{
                  height: "100%",
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 2,
                  paddingTop: 8,
                }}
                square
              >
                {RightPanel}
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    alignSelf: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Image
                    src="/red-sift-logo.svg"
                    width={100}
                    height={25}
                    alt="Red Sift"
                  />
                </Box>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
      </Box>
    </CenteredBox>
  );
};

export default SplitScreen;
